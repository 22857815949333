<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :span="5">
              <e-input
                title="姓名"
                :labelCol="7"
                :valueCol="16"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :span="5">
              <e-input
                title="手机号"
                :labelCol="7"
                :valueCol="16"
                v-model:value="queryParams.Mobile"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>

              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="addForm(() => $refs.eForm.open(null))"
              >
                添加用户
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'name'">
              <div class="username">
                <img :src="record.avatar" :alt="record.name" v-if="record.avatar" />
                <span>{{ record.name }}</span>
                <span v-if="record.sex == 1" class="sex man">
                  <ManOutlined />
                </span>
                <span v-else-if="record.sex == 2" class="sex feman">
                  <WomanOutlined />
                </span>
                <span v-else class="sex">
                  <ManOutlined />
                </span>
              </div>
            </template>

            <template v-if="column.key === 'storeName'">
              {{ record.storeName }}

              <a-tooltip>
                <template #title>绑定酒店</template>
                <a @click.stop="$refs.eBind.open(record)">
                  <UserSwitchOutlined />
                </a>
              </a-tooltip>
            </template>

            <template v-if="column.key === 'allowLogin'">
              <a-switch
                v-model:checked="record.allowLogin"
                :checkedValue="1"
                :unCheckedValue="0"
                checked-children="允许"
                un-checked-children="禁止"
                @change="changeAllowLogin(record.id, $event)"
              />
            </template>

            <template v-if="column.key === 'lastLoginTime'">
              <div v-if="record.lastLoginTime">
                <p>{{ $filters.formatDate(record.lastLoginTime) }}</p>
                <p>{{ record.lastLoginIP }}</p>
              </div>
              <div v-else>尚未登录</div>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>重置密码</template>
                <a @click.stop="password(record.id)"><UnlockOutlined /></a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="editForm(() => $refs.eForm.open(record))">
                  <EditOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EForm ref="eForm" :allowRoles="[2]" @ok="formOk" />
    <Password
      v-model:visible="visiblePassword"
      :userId="userId"
      @close="closePassword"
      @ok="okPassword"
    />
    <EBind ref="eBind" @ok="formOk" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  ManOutlined,
  WomanOutlined,
  UnlockOutlined,
  UserSwitchOutlined
} from '@ant-design/icons-vue'
import { UserClass } from '@/apis/user'
import page from '@/mixins/page'
import EForm from '@/components/forms/user/Form'
import Password from '@/components/forms/Password'
import EBind from '@/components/forms/store/Bind'

const api = new UserClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    ManOutlined,
    WomanOutlined,
    EForm,
    Password,
    UnlockOutlined,
    EBind,
    UserSwitchOutlined
  },
  setup () {
    const loading = ref(false)
    const activeKey = ref(-1)
    const height = ref(500)
    const width = ref(500)
    const userId = ref('')
    const visiblePassword = ref(false)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '用户名',
        dataIndex: 'uname',
        key: 'uname',
        width: 200
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 120
      },
      {
        title: '酒店',
        dataIndex: 'storeName',
        key: 'storeName',
        width: 120
      },
      {
        title: '最近登录统计',
        dataIndex: 'lastLoginTime',
        key: 'lastLoginTime',
        width: 200
      },
      {
        title: '允许登录',
        dataIndex: 'allowLogin',
        key: 'allowLogin',
        width: 100
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 120
      }
    ]
    return {
      activeKey,
      columns,
      loading,
      height,
      width,
      userId,
      visiblePassword
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      q.RoleIds = [2]
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    password (userId) {
      this.userId = userId
      this.visiblePassword = true
    },
    okPassword (e) {
      this.userId = ''
      this.visiblePassword = false
      this.$message.success('重置密码成功！')
    },
    closePassword () {
      this.userId = ''
      this.visiblePassword = false
    },
    changeAllowLogin (id, e) {
      api.state({ id, state: e }).then(() => {
        this.$message.success('设置成功！')
        this.getDataList(1)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },
    formOk () {
      this.getDataList(1)
    }
  }
})
</script>

<style lang="less" scoped>
</style>
